﻿import { saveAs } from 'file-saver';

import src from './maptools.html?url';

import icMapSvg from '../../images/svgs/ic_map.svg';
import closeSvg from '../../images/svgs/close.svg';
import printerSvg from '../../images/svgs/printer.svg';
import icPhotoSvg from '../../images/svgs/ic_photo.svg';
import icProfilerSvg from '../../images/svgs/ic_profiler.svg';
import editSvg from '../../images/svgs/edit.svg';
import isSelectSvg from '../../images/svgs/ic_select.svg';
import icSelectAllSvg from '../../images/svgs/ic_select_all.svg';
import icShapeFileSvg from '../../images/svgs/ic_shape_file.svg';
import icMeasureSvg from '../../images/svgs/ic_measure.svg';
import iconMeasureSvg from '../../images/svgs/icon-measure.svg';
import iconMeasure2Svg from '../../images/svgs/icon-measure2.svg';
import geosensorPng from '../../images/geosensor.png';

window.app.controller('maptoolsCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  '$mdToast',
  'modelService',
  'mapexportService',
  'mapService',
  'userAccountService',
  'utilService',
  maptoolsCtrl,
]);

function maptoolsCtrl(
  $scope,
  $rootScope,
  $sce,
  $mdToast,
  modelService,
  mapexportService,
  mapService,
  userAccountService,
  utilService,
) {
  $scope.src = $sce.trustAsResourceUrl(src);

  $scope.icMapSvg = icMapSvg;
  $scope.closeSvg = closeSvg;
  $scope.printerSvg = printerSvg;
  $scope.icPhotoSvg = icPhotoSvg;
  $scope.icProfilerSvg = icProfilerSvg;
  $scope.editSvg = editSvg;
  $scope.isSelectSvg = isSelectSvg;
  $scope.icSelectAllSvg = icSelectAllSvg;
  $scope.icShapeFileSvg = icShapeFileSvg;
  $scope.icMeasureSvg = icMeasureSvg;
  $scope.iconMeasureSvg = iconMeasureSvg;
  $scope.iconMeasure2Svg = iconMeasure2Svg;

  $scope.GeoIndex = () => {
    const model = modelService.getModel();
    const ids = model.getFeatures().map((f) => f.get('id'));
    utilService.GeoIndex(ids);
  };

  $scope.GeoSensor = utilService.GeoSensor;
  $scope.geosensorPng = geosensorPng;

  $scope.openMapExport = function () {
    if (!$scope.mapexport) {
      $scope.mapexport = true;
      modelService.update('model-tools', 'MAPEXPORT', 'ADD');
      mapexportService.init();
    } else {
      $scope.mapexport = false;
      modelService.update('model-tools', 'MAPEXPORT', 'DELETE');
      mapexportService.clear();
    }
  };

  $scope.closeMapExport = function () {
    if ($scope.mapexport) {
      $scope.mapexport = false;
      modelService.update('model-tools', 'MAPEXPORT', 'DELETE');
      mapexportService.clear();
    }
  };

  let tools;
  $rootScope.$on('model-tools', (_, model) => {
    tools = model.tools;
    $scope.$apply();
  });
  $scope.hasActiveTools = () => {
    return tools && tools.getSize();
  };
  $scope.hasActiveTool = (tool) => {
    return tools && tools.has(tool);
  };

  $scope.printScreen = function () {
    const canvas = document.getElementsByTagName('canvas')[0];
    canvas.toBlob(function (blob) {
      saveAs(blob, 'map.jpg');
    });
  };

  $scope.Print = function () {
    const canvases = document.getElementsByTagName('canvas');
    const canvas = canvases[canvases.length - 1];
    if (canvas.toDataURL()) {
      console.log('print canvas');
      const dataUrl = canvas.toDataURL();
      userAccountService
        .createDoc(dataUrl.replace('data:image/png;base64,', ''))
        .then(function (response) {
          let message = '';
          switch (response.status) {
            case 200:
              message = 'pdf is aangemaakt als ' + response.data;
              break;
            default:
              message =
                'Er is iets fout gegaan bij het aanmaken van de pdf (code ' +
                response.status +
                ')';
          }
          $mdToast.show($mdToast.simple(message));
        });
    } else {
      $mdToast.show(
        $mdToast.simple('Deze functie wordt niet ondersteund door uw browser'),
      );
    }
  };
}
