﻿import Point from 'ol/geom/Point';
import GeometryCollection from 'ol/geom/GeometryCollection';

import src from './onderzoeken.html?url';
import changeStatusHtml from './changeStatus.html?url';

import icSaveSvg from '../../images/svgs/ic_save.svg';
import closeSvg from '../../images/svgs/close.svg';
import editSvg from '../../images/svgs/edit.svg';
import icCheckSvg from '../../images/svgs/ic_check.svg';

const angular = window.angular;

window.app.controller('gridOnderzoekenCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  '$mdDialog',
  'gridOnderzoeken',
  'modelService',
  'gridFactory',
  'mapService',
  'utilService',
  'userAccountService',
  gridOnderzoekenControl,
]);

function gridOnderzoekenControl(
  $scope,
  $rootScope,
  $sce,
  $mdDialog,
  gridOnderzoeken,
  modelService,
  gridFactory,
  mapService,
  utilService,
  userAccountService,
) {
  $scope.src = $sce.trustAsResourceUrl(src);
  $scope.icCheckSvg = icCheckSvg;

  $scope.switchOperator = function (e) {
    console.log('switch' + e);
  };

  $scope.views = gridOnderzoeken.getViews();
  $scope.selectedView = $scope.views[0].id;
  $scope.groupings = gridOnderzoeken.getGroupings();
  $scope.selectedGrouping = $scope.groupings[0].id;
  $scope.selectionMethod = 'Cell';

  function setDisabled(value) {
    $scope.disabled = value;
    $scope.$apply();
  }

  $rootScope.$on('model-selectie', (_, model) => {
    if (model.grid.ONDERZOEKEN) {
      setDisabled(model.selectie.length === 0);
    }
  });

  $rootScope.$on('model-grid', (_, model) => {
    if (model.grid.ONDERZOEKEN) {
      setDisabled(true);
    }
  });

  $scope.zoomToOnderzoeken = () => {
    const onderzoeken = gridFactory.getRowsFromRange().map((row) => row.item),
      points = onderzoeken.map(
        (onderzoek) => new Point([onderzoek.x, onderzoek.y]),
      ),
      geometryCollection = new GeometryCollection(points),
      extent = geometryCollection.getExtent();
    mapService.fitToExtent(extent, {
      layer: 'Meetpunten',
      maxZoom: 14,
      center: [onderzoeken[0].x, onderzoeken[0].y],
    });
  };

  $scope.toggleFilterRow = function () {
    gridFactory.toggleFilterRow();
  };
  $scope.toggleSelectionMethod = function () {
    if ($scope.selectionMethod == 'Cell') {
      $scope.selectionMethod = 'Row';
    } else {
      $scope.selectionMethod = 'Cell';
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };

  $scope.setView = function (view) {
    if (view) {
      $scope.selectedView = view.name;
      var index = $scope.views.indexOf(view);
      gridOnderzoeken.setView(index);
    }
  };
  $scope.setSelectionMethod = function (e, method) {
    switch (method) {
      case 'Cell':
        $scope.selectionMethod = 'Cell';
        break;
      case 'Row':
        $scope.selectionMethod = 'Row';
        break;
    }
    gridFactory.setSelectionMethod($scope.selectionMethod);
  };
  $scope.setGrouping = function (grouping) {
    $scope.selectedGrouping = grouping;
    gridOnderzoeken.setGrouping($scope.selectedGrouping);
  };
  $scope.expandAllGroups = function () {
    gridFactory.expandAllGroups();
  };
  $scope.collapseAllGroups = function () {
    gridFactory.collapseAllGroups();
  };

  $scope.clearFilters = function () {
    gridFactory.clearFilter();
  };
  $scope.clearSelection = function () {
    gridFactory.clearSelection();
  };

  function ymdhms() {
    const date = new Date();
    let ret = '';
    [
      'getFullYear',
      'getMonth',
      'getDate',
      'getHours',
      'getMinutes',
      'getSeconds',
    ].forEach((key) => {
      let value = date[key]();
      if (key === 'getMonth') {
        // January yields 0
        value += 1;
      }
      if (('' + value).length === 1) {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
        // doesn't do "2-digit" for minute & second (in Chrome 99)
        ret += '0';
      }
      ret += value;
    });
    return ret;
  }

  function getColumns(ids) {
    return ids.map((id) => gridOnderzoeken.getColumn(id));
  }

  $scope.Export2Excel = function () {
    gridFactory.Export2Excel(`meetpunten.${ymdhms()}.xlsx`, {
      columns: gridOnderzoeken.getColumns('Alles').filter((column) => {
        return !['_checkbox_selector', 'aangeboden', 'pdf'].includes(column.id);
      }),
    });
  };
  $scope.Export2Txt = function () {
    gridFactory.Export2Csv('uitzet.txt', {
      columns: getColumns(['naam', 'x', 'y']),
      decimals: 3,
    });
  };
  $scope.Export2TxtZ = function () {
    gridFactory.Export2Csv('uitzetZ.txt', {
      columns: getColumns(['naam', 'x', 'y', 'z']),
      decimals: 3,
    });
  };
  $scope.Export2Csv = function () {
    gridFactory.Export2Csv('uitzet.csv', {
      columns: getColumns(['naam', 'tagklant', 'x', 'y']),
      includeHeaders: true,
      separator: ';',
      decimals: 2,
    });
  };
  $scope.Export2Pdf = function () {
    gridFactory.Export2Pdf();
  };
  $scope.Export2Kml = function (event, kml) {
    gridFactory.Export2Kml(kml);
  };

  $scope.GeoIndex = () => {
    const ids = gridFactory
      .getDataview()
      .getFilteredItems()
      .map((item) => item.id);
    utilService.GeoIndex(ids);
  };

  $scope.DeleteItems = async function (ev) {
    const MAX_DELETE_COUNT = 25;
    var rows = gridFactory.getRowsFromRange();
    const modusOK = modelService.getModel().modus().startsWith('P');
    if (rows.length > 0 && rows.length <= MAX_DELETE_COUNT && modusOK) {
      const ids = rows.map(({ item }) => item.id);
      const meetdata = await userAccountService.getOnderzoekenMeetdataById(ids);
      const metadata = await userAccountService.getOnderzoekenMetadataById(ids);
      const mData = meetdata.length || metadata.length;
      const mDataWarning =
        'Bijbehorende meet- en/of metadata wordt ook gewist!';
      let htmlContent = '<table><tbody>';
      for (const { item } of rows) {
        const { id, naam } = item;
        const contains = (set) => {
          const found = set.find(({ id: setId }) => setId === id);
          return found;
        };
        const mData = contains(meetdata) || contains(metadata);
        htmlContent += `<tr>`;
        htmlContent += `<td>${naam}</td>`;
        htmlContent += mData ? `<td>${mDataWarning}</td>` : '';
        htmlContent += `</tr>`;
      }
      htmlContent += '</tbody></table>';
      var confirm = $mdDialog
        .confirm({
          onComplete: function afterShowAnimation() {
            var $dialog = angular.element(document.querySelector('md-dialog'));
            var $actionsSection = $dialog.find('md-dialog-actions');
            var $cancelButton = $actionsSection.children()[0];
            var $confirmButton = $actionsSection.children()[1];
            angular.element($confirmButton).addClass('md-raised md-warn');
            angular.element($cancelButton).addClass('md-raised');
          },
        })
        .title('Volgende onderzoeken worden verwijderd:')
        .htmlContent(htmlContent)
        .ariaLabel('verwijder onderzoek')
        .targetEvent(ev)
        .ok('OK')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(
        function () {
          var projectnr = modelService.getModel().project.projectnr;
          var password = $mdDialog
            .prompt({
              onComplete: function afterShowAnimation() {
                var $dialog = angular.element(
                  document.querySelector('md-dialog'),
                );
                var $actionsSection = $dialog.find('md-dialog-actions');
                var $cancelButton = $actionsSection.children()[0];
                var $confirmButton = $actionsSection.children()[1];
                angular.element($confirmButton).addClass('md-raised md-warn');
                angular.element($cancelButton).addClass('md-raised');
              },
            })
            .title('Controle')
            .htmlContent(
              'Type projectnummer om onderzoek(en) te verwijderen: ' +
                projectnr +
                (mData ? `<br/><br/>${mDataWarning}` : ''),
            )
            .ariaLabel('geef wachtwoord voor verwijderen')
            .targetEvent(ev)
            .ok('OK')
            .cancel('Cancel');
          $mdDialog.show(password).then(
            function (result) {
              if (result == projectnr) {
                for (const i in rows) {
                  gridFactory.DeleteRows(rows[i].item);
                }
              } else {
                showMessage(ev, 'Verkeerd wachtwoord');
              }
            },
            function () {},
          );
        },
        function () {},
      );
    } else {
      var message = 'Verwijderen kan enkel in P- of PF-modus';
      if (modusOK) {
        message =
          rows.length > 0
            ? 'Te veel onderzoeken geselecteerd (' +
              rows.length +
              ', max = ' +
              MAX_DELETE_COUNT +
              ')'
            : 'Geen onderzoeken geselecteerd';
      }
      showMessage(ev, message);
    }
  };

  function showMessage(ev, message) {
    var alert = $mdDialog
      .alert({
        onComplete: function afterShowAnimation() {
          var $dialog = angular.element(document.querySelector('md-dialog'));
          var $actionsSection = $dialog.find('md-dialog-actions');
          var $cancelButton = $actionsSection.children()[0];
          var $confirmButton = $actionsSection.children()[1];
          angular.element($confirmButton).addClass('md-raised md-warn');
          angular.element($cancelButton).addClass('md-raised');
        },
      })
      .clickOutsideToClose(true)
      .title('Opmerking')
      .textContent(message)
      .ariaLabel('Opmerking Verwijder onderzoeken')
      .ok('OK')
      .targetEvent(ev);
    $mdDialog.show(alert).then(
      function () {},
      function () {},
    );
  }

  $scope.getDocument = function () {
    window.open('link', '_blank', 'fullscreen=yes');
  };

  // Temporarly solution for bug we found once.
  // Isn't the best name but should be fixed anyway.
  $scope.reset = function () {
    gridFactory.resizeGrid();
  };

  $scope.changeStatus = function () {
    var dataview = gridFactory.getDataview();
    var items = dataview.getItems();

    $mdDialog
      .show({
        multiple: true,
        controller: changeStatusController,
        templateUrl: $sce.trustAsResourceUrl(changeStatusHtml),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: { items: items },
      })
      .then(
        function (changedItems) {
          if (changedItems && changedItems.length > 0) {
            changedItems.forEach((chi) => {
              if (chi.onderzoekstatus === '99-N_UITV') {
                const oldItem = items.find(({ id }) => id === chi.id);
                if (oldItem.xystatus === 'XYZ_Veldwerk') {
                  chi.xystatus = 'XYZ_Uitgezet';
                }
              }
            });
            gridOnderzoeken.saveItems(changedItems);
          } else {
            gridOnderzoeken.invalidateAll();
          }
        },
        function () {
          gridOnderzoeken.invalidateAll();
        },
      );
  };

  function changeStatusController($scope, $rootScope, items) {
    $scope.icSaveSvg = icSaveSvg;
    $scope.closeSvg = closeSvg;
    $scope.editSvg = editSvg;

    $scope.potcategorieList = [
      { naam: 'ALL', omschrijving: 'geen filter' },
      { naam: 'S', omschrijving: 'S: Sonderen' },
      { naam: 'B', omschrijving: 'B: Boren' },
      { naam: 'G', omschrijving: 'G: Geomonitoring' },
      { naam: 'M', omschrijving: 'M: Milieu' },
      { naam: 'O', omschrijving: 'O: Overig' },
    ];
    $scope.filteredStatusList = filterOnderzoekstatussen(
      $rootScope.refdata.onderzoekstatus,
    );
    $scope.selectionStatusList = setOnderzoekstatussen(
      $rootScope.refdata.onderzoekstatus,
    );
    $scope.statusList = $rootScope.refdata.onderzoekstatus;
    $scope.coordinatenList = [
      'Conform uitzet/veldwerkcoordinaten',
      'Afwijkend: nogmaals inmeten!',
    ];

    $scope.global = {
      filterStatus: 'OPEN',
      filterPotcategorie: 'ALL',
      status: $scope.statusList['3. UITV_OK'].naam, //$scope.statusList[Object.keys($scope.statusList)[0]].naam,
      coordinaten: $scope.coordinatenList[0],
      opmerking: '',
      selectAll: false,
    };

    var allItems = [];
    for (const i in items) {
      var alteredItem = angular.copy(items[i]);
      alteredItem.newOnderzoekstatus = $scope.global.status;
      alteredItem.onderzoekstatusopmerking = '';
      alteredItem.coordinaten = $scope.global.coordinaten; // $scope.coordinatenList[0];
      allItems.push(alteredItem);
    }
    $scope.filteredItems = filterItems(
      allItems,
      $scope.global,
      $scope.filteredStatusList,
    );

    var selectedItems = [];

    $scope.cancel = function () {
      $mdDialog.hide(null);
    };

    const oldItems = items;
    $scope.save = function () {
      var itemsToSave = [];
      var copiedItems = JSON.parse(JSON.stringify(selectedItems));
      for (var i in copiedItems) {
        var item = copiedItems[i];
        if (item.coordinaten == 'Afwijkend: nogmaals inmeten!') {
          item.xystatus = $rootScope.refdata.xystatus['XYZ_Uitgezet'].naam;
        }
        delete item.coordinaten;

        item.onderzoekstatus = item.newOnderzoekstatus;
        delete item.newOnderzoekstatus;

        const changes = utilService.changesById(item, oldItems);
        itemsToSave.push(changes);
      }
      $mdDialog.hide(itemsToSave);
    };

    $scope.getStatusOmschrijving = function (statusnaam) {
      return $rootScope.refdata.onderzoekstatus[statusnaam].omschrijving;
    };

    $scope.selectAll = function () {
      selectedItems = [];
      if ($scope.global.selectAll) {
        for (const i in $scope.filteredItems) {
          resetItem($scope.filteredItems[i]);
          selectedItems.push($scope.filteredItems[i]);
        }
      }
    };

    $scope.filter = function () {
      $scope.filteredItems = filterItems(
        allItems,
        $scope.global,
        $scope.filteredStatusList,
      );
    };

    function resetItem(item) {
      var idxFiltered = $scope.filteredItems.indexOf(item);
      var idxAll = allItems.indexOf(item);

      item.newOnderzoekstatus = $scope.global.status;
      item.coordinaten = $scope.global.coordinaten;
      item.onderzoekstatusopmerking = '';

      $scope.filteredItems[idxFiltered] = item;
      allItems[idxAll] = item;

      return item;
    }

    $scope.select = function (item) {
      var idx = selectedItems.indexOf(item);
      if (idx == -1) {
        selectedItems.push(resetItem(item));
      } else {
        selectedItems.splice(idx, 1);
      }
    };

    $scope.areSomeSelected = function () {
      return selectedItems.length > 0;
    };

    $scope.isSelected = function (item) {
      return selectedItems.indexOf(item) != -1;
    };

    $scope.getStatusColor = function () {
      return '#ff0000';
    };

    $scope.isDifferent = function (item) {
      var statusDif = item.newOnderzoekstatus != $scope.global.status;
      var coordinatenDif = item.coordinaten != $scope.global.coordinaten;
      return statusDif || coordinatenDif;
    };

    $scope.isDifferentStatus = function (item) {
      var statusDif = item.newOnderzoekstatus != $scope.global.status;
      return statusDif;
    };

    $scope.isDifferentCoords = function (item) {
      var coordinatenDif = item.coordinaten != $scope.global.coordinaten;
      return coordinatenDif;
    };
  }
  changeStatusController.$inject = ['$scope', '$rootScope', 'items'];

  function setOnderzoekstatussen() {
    var result = {};
    result['OPEN'] = { naam: 'OPEN', omschrijving: 'status open' };
    result['ALL'] = { naam: 'ALL', omschrijving: 'geen filter' };
    return result;
  }

  function filterOnderzoekstatussen(onderzoekstatussen) {
    var result = {};
    result['1. INIT'] = onderzoekstatussen['1. INIT'];
    result['1. BB/TK_OK'] = onderzoekstatussen['1. BB/TK_OK'];
    result['2. GO_OK'] = onderzoekstatussen['2. GO_OK'];
    return result;
  }

  function filterItems(items, global, filteredStatusList) {
    var filteredItems = [];
    for (var i in items) {
      var statusfilter;
      if (global.filterStatus == 'OPEN') {
        statusfilter = false;
        for (var status in filteredStatusList) {
          if (items[i].onderzoekstatus == status) {
            statusfilter = true;
          }
        }
      } else {
        statusfilter = true;
      }
      var optcategoriefilter =
        global.filterPotcategorie == 'ALL' ||
        items[i].primair_onderzoekstype == global.filterPotcategorie;
      if (statusfilter && optcategoriefilter) {
        filteredItems.push(items[i]);
      }
    }
    return filteredItems;
  }
}
