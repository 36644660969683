﻿import Collection from 'ol/Collection';

import multiselectHtml from './multiselect.html?url';

import closeSvg from '../../images/svgs/close.svg';

const angular = window.angular;

window.app.service('mapSelectionService', [
  '$rootScope',
  '$mdDialog',
  '$sce',
  'modelService',
  mapSelectionService,
]);

function mapSelectionService($rootScope, $mdDialog, $sce, modelService) {
  const Service = {
    SelectSomething,
    addIdentifyLayer,
    removeIdentifyLayer,
    hasIdentifyLayers,
  };

  const identifyLayers = {
    wms: [],
    vector: [],
  };

  function addIdentifyLayer(layer) {
    if (
      layer.layer_type === 'WFS_EXTERN' ||
      layer.layer_type === 'KMLSERVICE'
    ) {
      identifyLayers.vector.push(layer.name);
    } else {
      identifyLayers.wms.push(layer.name);
    }
  }

  function removeIdentifyLayer(layer) {
    for (const layerNames of Object.values(identifyLayers)) {
      const i = layerNames.indexOf(layer.name);
      if (i != -1) {
        layerNames.splice(i, 1);
        break;
      }
    }
  }

  function hasIdentifyLayers() {
    for (const layerNames of Object.values(identifyLayers)) {
      if (layerNames.length) {
        return true;
      }
    }
    return false;
  }

  function hasIdentifyLayer(name) {
    for (const layerNames of Object.values(identifyLayers)) {
      if (layerNames.includes(name)) {
        return true;
      }
    }
    return false;
  }

  let selectedFeatures = null;

  function SelectSomething(e) {
    if ($rootScope.streetview || $rootScope.streetsmart) {
      return true;
    }
    selectedFeatures = getFeatures(e);
    if (modelService.getModel().tools.has('POINTEDITOR')) {
      var meetpunten = selectedFeatures
        .filter(({ layername }) => {
          return layername == 'Meetpunten';
        })
        .filter(({ feature }) => {
          const { bron } = feature.getProperties();
          return bron == 'WEP';
        });
      var itemIDs = meetpunten.map((m) => m.feature.get('id'));
      modelService.update('model-selectie', itemIDs, true);
    } else if (
      selectedFeatures.length > 0 &&
      hasMeetpuntenOrProjecten(selectedFeatures)
    ) {
      $mdDialog
        .show({
          controller: multiselectDialogController,
          templateUrl: $sce.trustAsResourceUrl(multiselectHtml),
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          escapeToClose: false,
          locals: { items: selectedFeatures },
        })
        .then(
          function () {},
          function () {},
        );
      return true;
    } else if (hasIdentifyLayers()) {
      const oneLayer = false;
      if (oneLayer && identifyLayers.wms.length) {
        // FIXME: unreachable code, since oneLayer = false => ???
        const layer = getLayer(identifyLayers.wms[0]);
        if (layer != null) {
          const viewResolution = /** @type {number} */ (
            window.map.getView().getResolution()
          );
          const source = layer.getSource();
          const url = source.getFeatureInfoUrl(
            e.coordinate,
            viewResolution,
            window.map.getView().getProjection(),
            { INFO_FORMAT: 'text/html' },
          );
          if (url) {
            fetch(url)
              .then(function (response) {
                return response.text();
              })
              .then(function (html) {
                $mdDialog.show({
                  template: html,
                  clickOutsideToClose: true,
                  escapeToClose: true,
                });
              });
          }
          return true;
        }
      } else {
        const requests = [];
        for (const ilayer in identifyLayers.wms) {
          const layer = getLayer(identifyLayers.wms[ilayer]);
          if (layer != null) {
            const viewResolution = window.map.getView().getResolution();
            const source = layer.getSource();
            const url = source.getFeatureInfoUrl(
              e.coordinate,
              viewResolution,
              window.map.getView().getProjection(),
              { INFO_FORMAT: 'text/html' },
            );
            if (url) {
              const options = {};
              requests.push(
                fetch(url, options).then(function (response) {
                  return response.text();
                }),
              );
            }
          }
        }
        if (requests.length > 0) {
          Promise.all(requests)
            .then(function (values) {
              var html = [];
              for (var i = 0; i < requests.length; i++) {
                html.push(values[i]);
              }
              html.push(getVectorInfo(false));
              return html.join('');
            })
            .then(function (html) {
              $mdDialog.show({
                template: html,
                clickOutsideToClose: true,
                escapeToClose: true,
              });
            });
          return true;
        } else if (selectedFeatures.length > 0) {
          var html = getVectorInfo(true);
          $mdDialog.show({
            multiple: true,
            controller: featureinfoDialogController,
            template: html,
            clickOutsideToClose: true,
            escapeToClose: true,
          });
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  function hasMeetpuntenOrProjecten(features) {
    var found = features.find(
      (f) => f.layername == 'Meetpunten' || f.layername == 'Projecten',
    );
    return found != undefined;
  }

  const vectorInfoStyle = `
    <style type="text/css">
        table.featureInfo, table.featureInfo td, table.featureInfo th {
            border:1px solid #ddd;
            border-collapse:collapse;
            margin:0;
            padding:0;
            font-size: 90%;
            padding:.2em .1em;
        }
        table.featureInfo th {
            padding:.2em .2em;
            font-weight:bold;
            background:#eee;
        }
        table.featureInfo td{
            background:#fff;
        }
        table.featureInfo tr.odd td{
            background:#eee;
        }
        table.featureInfo caption{
            text-align:left;
            font-size:100%;
            font-weight:bold;
            padding:.2em .2em;
        }
    </style>
  `;

  function featureinfoDialogController($scope, $mdDialog) {
    $scope.balloon = (index) => {
      const balloonStyle = selectedFeatures[index].feature.get('balloonStyle');
      $mdDialog.show({
        multiple: true,
        template: `
          <div style="padding: 1rem; background-color: ${balloonStyle.bgColor}; color: ${balloonStyle.textColor};">
              ${balloonStyle.text}
          </div
        `,
        clickOutsideToClose: true,
        escapeToClose: true,
      });
    };
  }
  featureinfoDialogController.$inject = ['$scope', '$mdDialog'];

  function getVectorInfo(withStyle) {
    let html = withStyle ? [vectorInfoStyle] : [];
    selectedFeatures.forEach(({ layername, feature }, index) => {
      if (feature) {
        let properties = angular.copy(feature.getProperties()),
          row1 = '',
          row2 = '';
        if (properties.balloonStyle) {
          delete properties.geometry;
          delete properties.styleUrl;
          if (properties.Naam) {
            delete properties.name;
          }
          properties['Details'] = `
            <div style="display: flex;">
                <md-icon ng-click="balloon(${index})" class="material-symbols-outlined" aria-label="Details opvragen">
                    info
                    <md-tooltip md-direction="right">Details opvragen</md-tooltip>
                </md-icon>
            </div>
          `;
          delete properties.balloonStyle;
        }
        for (const [key, value] of Object.entries(properties)) {
          row1 += `<th>${key}</th>`;
          row2 += `<td>${value}</td>`;
        }
        let table = '<table class="featureInfo">';
        table += `<caption class="featureInfo">${layername}</caption>`;
        table += `<tr>${row1}</tr>`;
        table += `<tr>${row2}</tr>`;
        table += '</table>';
        html.push(table);
      }
    });
    return `
      <div style="padding: 1rem;">
          ${html.join('')}
      </div
    `;
  }

  function getLayer(title) {
    var layers = window.map.getLayers().getArray();
    var num = layers.length;
    for (var i = 0; i < num; i++) {
      var layer = layers[i];
      if (layer.get('title') === title) {
        return layer;
      }
    }
  }

  function getFeatures(e) {
    var selectedFeatures = new Collection();
    window.map.forEachFeatureAtPixel(
      e.pixel,
      function (feature, layer) {
        if (!layer) {
          console.log('doubleclicked the selection polygon');
        } else {
          var title = layer.get('title');
          if (title !== undefined) {
            //all layers or limited to the ones in the list
            if (!hasIdentifyLayers() || hasIdentifyLayer(layer.get('title'))) {
              selectedFeatures.push({
                layername: title,
                feature: feature,
                layer: layer,
                order:
                  title === 'Meetpunten'
                    ? feature.get('projectnr') + feature.get('naam')
                    : feature.get('subprojectnr'),
              });
            }
          }
        }
      },
      { hitTolerance: 5 },
    );
    return selectedFeatures.getArray();
  }

  function showFeatureInfo(selection) {
    var feature = selection.feature;
    var layer = selection.layer;

    if (layer.get('title') == 'Projecten') {
      const showTab = true;
      modelService.update('model-project', feature.get('id'), showTab);
    } else {
      const onderzoekSelectedFromMap = true;
      modelService.update(
        'model-selectie',
        [feature.get('id')],
        onderzoekSelectedFromMap,
      );
    }
  }

  function multiselectDialogController($scope, $mdDialog, items) {
    $scope.closeSvg = closeSvg;

    $scope.items = items;
    $scope.cancel = function () {
      $mdDialog.hide();
    };
    $scope.select = function (item) {
      showFeatureInfo(item);
      $mdDialog.hide();
    };
  }
  multiselectDialogController.$inject = ['$scope', '$mdDialog', 'items'];

  return Service;
}
