﻿const { protocol, hostname } = window.location;
const dev = protocol === 'http:';
const prefix = dev ? `https://${hostname}:7443` : '';

let profilerURL = prefix + '/profiler/viewer.php';
// save VITE_PHP_LOCAL=true in file .env.local (ignored by git)
const localPHP = dev && import.meta.env.VITE_PHP_LOCAL === 'true';
if (localPHP) {
  profilerURL = prefix + '/port5000/viewer.php';
}

const config = {
  dev,
  local: dev || hostname === 'localhost',
  prefix,
  serviceURL: prefix + '/geowep/api',
  profilerURL,
};

export default config;
