﻿import Geometry from 'ol/geom/Geometry';
import cloneDeep from 'lodash.clonedeep';

window.app.service('modelService', [
  '$rootScope',
  'userAccountService',
  modelService,
]);

function modelService($rootScope, userAccountService) {
  // Model bevat de "state data" van de applicatie.
  //
  // Het Model kan alleen worden bijgewerkt door het sturen van een Message,
  // optioneel vergezeld van een beetje nieuwe data.
  //
  // Bij elk Message-type definieer je de bijbehorende Update-functie,
  // die het Model moet bijwerken aan de hand van de optioneel meegestuurde data.
  //
  // Na het bijwerken volgt een Event met dezelfde naam als de Message,
  // vergezeld met de actuele stand van het Model (read-only).
  //
  // Gebruik instanties van Enum, Selection en TypedValue in het Model
  // om makkelijk consistentie af te dwingen.

  const Model = {
    // eslint-disable-next-line no-unused-vars
    getFeatures: (filtered) => [],
    grid: new TypedValue(
      new EnumType('grid', [
        'NONE',
        'ONDERZOEKEN',
        'WERKZAAMHEDEN',
        'METADATA',
        'KLICNETWERKEN',
        'PLANTEKENINGEN',
        'PROJECTKAARTEN',
        'NOTITIES',
        'LOGBOEK',
      ]),
    ),
    bronnen: new Selection(
      new EnumType('bronnen', [
        ['WEP', 5],
        ['DINO', 2],
        ['BRO', 1],
        ['DER', 4],
      ]),
    ),
    labels: new Selection(new EnumType('labels', ['PROJECTNR', 'TAGKLANT']), [
      'PROJECTNR',
    ]),
    presentatie: new Enum(
      new EnumType('presentatie', [
        ['default', 'Default kleurschema'],
        ['onderzoekstatus', 'Status onderzoek'],
        ['xystatus', 'Status XY'],
        ['diepte_mmv', 'Diepte m-mv (behaald)'],
        ['diepte_mnap', 'Diepte m NAP (behaald)'],
        ['uitgevoerd', 'Jaar uitvoering'],
      ]),
    ),
    filter: {
      KAART: undefined,
      GRID: undefined,
      fingerprint: undefined,
    },
    exclude: {
      PLAN: undefined,
      PROJECT: undefined,
      KLIC: undefined,
      NOTITIE: undefined,
    },
    selectie: [],
    onderzoekSelectedFromMap: false,
    project: null,
    tools: new Selection(
      new EnumType('tools', [
        'SELECTION',
        'NOTITIES',
        'AREA',
        'LENGTH',
        'PROFILER',
        'MAPEXPORT',
        'ADDONDERZOEK',
        'POINTEDITOR',
      ]),
      [],
    ),
    tabs: false,
    modus: () =>
      (!Model.project || Model.project.bronnen.getSize() ? 'K' : '') +
      (Model.project ? 'P' : '') +
      (Model.filter.KAART || Model.filter.GRID ? 'F' : ''),
  };

  const Modus = new Enum(
    new EnumType('modus', ['K', 'KF', 'P', 'PF', 'KP', 'KPF']),
  );

  function updateModus() {
    const newModus = Model.modus();
    if (Modus.key !== newModus) {
      Modus.set(newModus);
      Service.update('model-modus');
    }
  }

  // Specificeer elk Message-type met een unieke naam en de bijbehorende
  // Update-functie.
  //
  // De Update-functies van de Messages zijn de (enige) plek waar je de data
  // in het Model kan wijzigen.
  //
  // Elke Update-functie wordt aangeroepen met een `arg`-object, waarmee
  // je de meegestuurde data kan uitlezen.
  // Elke call op arg levert de volgende parameter, waarvan het datatype
  // geverifieerd wordt volgens de call die je doet.
  //
  // Elke Update-functie wordt uitgevoerd in een async context, binnen
  // een try...catch-block.
  // Zo kan je makkelijk `waarde = await <Promise>` doen; een reject op
  // die Promise levert dan een throw op en de update faalt en stopt.
  // Moet je wel `async` voor je Update-functie zetten.
  //
  // Return iets truish vanuit de Update-functie om géén Event uit te sturen.
  // Dus als je zeker weet dat er niets gewijzigd is in het Model.
  // Eventueel met behulp van de `equal`-functie.

  const Message = new EnumType('message', [
    ['model-modus'],
    ['model-projectTabs'],
    [
      'model-getFeatures',
      (arg) => {
        Model.getFeatures = arg.function();
      },
    ],
    [
      'model-grid',
      (arg) => {
        const type = arg.string();
        if (type === 'NONE' && Model.grid.NONE) {
          return true;
        }
        const element = arg.maybe(arg.string);
        Model.grid.set(type, element);
      },
    ],
    [
      'model-bronnen',
      (arg) => {
        (Model.project ? Model.project.bronnen : Model.bronnen).toggle(
          arg.string(),
        );
        updateModus();
      },
    ],
    [
      'model-labels',
      (arg) => {
        Model.labels.toggle(arg.string());
      },
    ],
    [
      'model-presentatie',
      (arg) => {
        Model.presentatie.set(arg.string());
      },
    ],
    [
      'model-selectie',
      (arg) => {
        const selectie = equal(arg.integerArray(), Model, 'selectie'),
          onderzoekSelectedFromMap = equal(
            arg.booleanish(),
            Model,
            'onderzoekSelectedFromMap',
          );
        return selectie && onderzoekSelectedFromMap;
      },
    ],
    [
      'model-filter-kaart',
      (arg) => {
        const geom = arg.maybe(arg.instance(Geometry));
        return equal(geom, Model.filter, 'KAART', () => {
          Model.filter.showTab = arg.booleanish();
          Model.filter.fingerprint = Math.random();
          updateModus();
        });
      },
    ],
    [
      'model-filter-grid',
      (arg) => {
        return equal(arg.maybe(arg.integerArray), Model.filter, 'GRID', () => {
          Model.filter.showTab = arg.booleanish();
          updateModus();
        });
      },
    ],
    [
      'model-project',
      async (arg) => {
        const id = arg.maybe(arg.integer);
        const showTab = arg.booleanish();
        if (id) {
          if (!Model.project || Model.project.id !== id) {
            Model.project = await userAccountService.getProjectById(id);
          }
          Model.project.showTab = showTab;
          Model.project.bronnen = new Selection(Model.bronnen.getType(), []);
        } else {
          Model.project = null;
        }
        Model.filter.GRID = undefined;
        Model.filter.KAART = undefined;
        Model.exclude.PLAN = undefined;
        Model.exclude.PROJECT = undefined;
        Model.exclude.KLIC = undefined;
        Model.exclude.NOTITIE = undefined;
        updateModus();
        Service.update('model-selectie', []);
      },
    ],
    [
      'model-exclude-plan',
      (arg) => {
        return equal(arg.maybe(arg.integerArray), Model.exclude, 'PLAN');
      },
    ],
    [
      'model-exclude-project',
      (arg) => {
        return equal(arg.maybe(arg.integerArray), Model.exclude, 'PROJECT');
      },
    ],
    [
      'model-exclude-klic',
      (arg) => {
        return equal(arg.maybe(arg.stringArray), Model.exclude, 'KLIC');
      },
    ],
    [
      'model-exclude-notitie',
      (arg) => {
        return equal(arg.maybe(arg.integerArray), Model.exclude, 'NOTITIE');
      },
    ],
    [
      'model-tools',
      (arg) => {
        const tool = arg.string();
        const action = new Enum(
          new EnumType('action', ['ADD', 'DELETE']),
          arg.string(),
        );
        const add = action.key === 'ADD';
        const has = Model.tools.has(tool);
        if (add === has) {
          return true;
        } else {
          (add ? Model.tools.add : Model.tools.delete)(tool);
        }
      },
    ],
    [
      'model-tabs',
      (arg) => {
        Model.tabs = arg.boolean();
      },
    ],
  ]);

  function equal(value, object, key, cb) {
    if (!(key in object)) {
      throw "key '" + key + "' not in object: " + object;
    } else if (window.angular.equals(value, object[key])) {
      // Tell them nothing changes.
      return true;
    } else {
      // Change the thing.
      object[key] = value;
      // Run the optional callback function.
      (cb || (() => undefined))();
    }
  }

  // Dit is je API voor de modelService:
  //
  // Je kan de actuele/initiële stand van het Model opvragen (read-only).
  //
  // En je kan de Update-functie van een specifieke Message laten uitvoeren
  // met 0 of meer data-parameters.
  //
  // Tot slot kan je reageren op Events, waarbij je dan weer een
  // read-only kopie meekrijgt van het Model, dat de Update-functie van de
  // betreffende Message net heeft bijgewerkt:
  //
  // `$rootScope.$on("<message-naam>", (_, model) => {...})`
  //
  // Merk op dat je de modelService zelf niet nodig hebt (maar de $rootScope
  // wel) om (alleen maar) naar de events te luisteren.

  const Service = {
    getModel: getModel,
    update: (message, ...args) => {
      // conditional breakpoint on next line to get a meaningful
      // stacktrace: message === "model-..."
      Queue.add(message, ...args);
    },
  };

  // Alles hieronder is modelService-intern.

  // Alle ontvangen messages komen in een Queue, die ze voortdurend
  // zo spoedig mogelijk, in volgorde, en één voor één afhandelt.

  const Queue = new (function () {
    const queue = [];
    this.add = (message, ...args) =>
      queue.push({
        message: message,
        args: args,
      });
    setTimeout(async function loop() {
      let item;
      while ((item = queue.shift())) {
        try {
          const updateFunction =
            new Enum(Message, item.message).value || (() => {});
          const cancel = !!(await updateFunction(new Arg(...item.args)));
          // logpoint op volgende regel: !!cancel, item.message, item.args, Model
          if (!cancel) {
            $rootScope.$emit(item.message, getModel());
          }
        } catch (error) {
          console.error('modelService: ', error);
        }
      }
      setTimeout(loop);
    }, 500);
  })();

  // Een EnumType specificeert een beperkte set van mogelijke waardes,
  // optioneel gekoppeld aan een bijbehorend data-element.
  //
  // Je moet een naam opgeven voor je EnumType, en
  // - ofwel een eendimesionale array van waarde-opties,
  // - ofwel een tweedimensionale array met key-value-pairs (dus
  //   combinaties van een waarde-optie met z'n data-element).
  //
  // Hoewel je voor de keys (de waarde-opties) meestal strings zal kiezen,
  // mogen zowel de keys als de values (de data-elementen) van elk
  // willekeurig datatype zijn.

  function EnumType(name, entries) {
    this.name = name;
    try {
      entries = new Map(entries);
    } catch {
      entries = new Map(entries.map((key) => [key, undefined]));
    }
    const options = [];
    entries.forEach((value, key) => options.push({ key: key, value: value }));
    this.options = Object.freeze(options);
    this.default = Object.freeze(this.options[0].key);
    this.has = (key) => entries.has(key);
    this.get = (key) => entries.get(key);
    this.getMap = () => new Map(entries);
  }

  // Een Enum is de gekozen waarde uit de opties die gedefinieerd zijn
  // door het gegeven enumType.
  //
  // De initiële waarde geef je middels de key-parameter en als je die
  // weglaat is de defaultwaarde de eerte optie uit het enumType.
  //
  // Je wijzigt de gekozen waarde met de set-methode.
  // Die throwt een error als de gegeven waarde niet geldig is.
  //
  // De key-property geeft de gekozen waarde.
  // De value-property geeft het optioneel bijbehorende data-element
  // (zoals vastgelegd in het enumType).

  function Enum(enumType, key) {
    this.getType = () => Object.freeze(enumType);
    this.set = (key) => {
      key = key || enumType.default;
      if (enumType.has(key)) {
        this.key = key;
        this.value = enumType.get(key);
        return this;
      } else {
        throw "invalid key for enumType '" + enumType.name + "': " + key;
      }
    };
    this.set(key);
  }

  // Een Selection is een Set met Enums van een bepaald enumType.
  //
  // Initieel zijn alle opties geslecteed, tenzij je de initial-parameter
  // meegeeft (een eendimensionale array van te selecteren waarde-opties).
  //
  // De add-, delete- en toggle-methodes zetten waardes aan en uit.
  // De has-methode test of een waarde geselecteerd is.
  // De get-methode geeft het data-element van een waarde-optie, mits die
  // optie ook geselecteerd is.

  function Selection(enumType, initial) {
    this.getType = () => Object.freeze(enumType);
    const selection = initial ? new Map() : enumType.getMap();
    if (initial) {
      initial.forEach((key) => add(key));
    }

    function add(key) {
      selection.set(key, instance(key).value);
    }

    function instance(key) {
      // throws on invalid key
      return new Enum(enumType, key);
    }
    this.add = (key) => {
      add(key);
      return this;
    };
    this.delete = (key) => {
      selection.delete(instance(key).key);
      return this;
    };
    this.toggle = (key) => {
      if (this.has(key)) {
        return this.delete(key);
      } else {
        return this.add(key);
      }
    };
    this.has = (key) => selection.has(key);
    this.get = (key) => selection.get(key);
    this.keys = () => selection.keys();
    this.values = () => selection.values();
    this.forEach = (fn) => selection.forEach(fn);
    this.getSize = () => selection.size;
    this.toString = () => {
      const keys = [];
      selection.forEach((_, key) => keys.push(key));
      return '' + keys;
    };
  }

  // Een TypedValue hangt een dynamische waarde aan één gekozen waarde-optie
  // van de gegeven enumType.
  //
  // Die EnumType heeft dan typisch geen vast gekoppelde data-elementen,
  // maar alleen een eendimensionale lijst van string-waarde-opties.
  //
  // Je TypedValue-object heeft dan één property, met de naam van de gekozen
  // waarde.
  //
  // De set-methode selecteert de gegeven optie en bewaart de gegeven data.
  // De previous-property geeft de vorige optie+data.
  // De key-property geeft de geselecteerde optie.
  // De value-property geeft de huidige waarde.

  function TypedValue(enumType, initialType, initialValue) {
    this.getType = () => Object.freeze(enumType);
    const type = new Enum(enumType, initialType);
    this.set = (key, value) => {
      this.previous = {};
      this.previous[type.key] = this[type.key];
      this.previous.key = type.key;
      this.previous.value = this[type.key];
      delete this[type.key];
      type.set(key);
      value = Object.freeze(value === undefined ? true : value);
      this[key] = value;
      this.key = key;
      this.value = value;
    };
    this.set(type.key, initialValue);
  }

  // Elke Update-functie krijgt een `new Arg()` binnen met de data-parameters
  // die met de Message meegestuurd werden.

  function Arg(...args) {
    function test(assert, maybe) {
      if (maybe) {
        assert = Assert.maybe(assert);
      }
      return assert(args.shift());
    }
    this.maybe = (arg) => arg(true);
    this.function = (maybe) => test(Assert.function, maybe);
    this.string = (maybe) => test(Assert.string, maybe);
    this.boolean = (maybe) => test(Assert.boolean, maybe);
    this.booleanish = (maybe) => test(Assert.booleanish, maybe);
    this.integer = (maybe) => test(Assert.integer, maybe);
    this.array = (map) => (maybe) => test(Assert.array(map), maybe);
    this.integerArray = (maybe) => this.array(Assert.integer)(maybe);
    this.stringArray = (maybe) => this.array(Assert.string)(maybe);
    this.instance = (type) => (maybe) => test(Assert.instance(type), maybe);
  }

  // Arg gebruikt het Assert-object voor de datatype-verificatie.

  const Assert = new (function () {
    const test = (condition, error) => {
      if (!condition) {
        throw error;
      }
    };
    const type = (value, type) => {
      test(typeof value === type, 'expected: ' + type);
      return value;
    };
    this.function = (value) => type(value, 'function');
    this.string = (value) => type(value, 'string');
    this.boolean = (value) => type(value, 'boolean');
    this.booleanish = (value) => !!value;
    this.integer = (value) => parseInt(value);
    this.array = (map) => (value) => {
      test(value instanceof Array, 'expected: Array');
      return value.map(map || ((e) => e));
    };
    this.maybe = (assert) => (value) => {
      return value === undefined ? undefined : assert(value);
    };
    this.instance = (type) => (value) => {
      test(value instanceof type, 'unexpected instance type');
      return value;
    };
  })();

  // Overal buiten de Update-functie heb je de Model-data altijd alleen
  // als kopie beschikbaar.

  function getModel() {
    const model = cloneDeep(Model);
    model.__fresh = () => getModel();
    return model;
  }

  return Service;
}
